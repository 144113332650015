<template>
  <div class="page">
    <div class="page_body">
      <div class="account">
        <p>我的订单</p>
        <span>已累计返利：{{amount.total_commission}} 元，已累计提现：{{amount.total_rebate_money}} 元</span>
      </div>
      <van-tabs v-model="tabIndex" @click="change_tab" sticky color="#ff5050">
        <van-tab :title="item.title" :name="index" v-for="(item,index) in tab" :key="index">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              @load="_getData()"
              v-model="loading"
              :finished="finished"
              :offset="200"
              finished-text="没有更多了"
            >
              <van-cell-group class="order-item" v-for="(data,index) in item.data" :key="index">
                <div class="order_body">
                  <div class="order_money">{{data.order_time}}</div>
                  <div class="order_money already" v-if="data.status_desc=='已付款'">待结算</div>

                  <div
                    class="order_money already"
                    v-else-if="data.status_desc=='已结算'"
                    style="color:#0BB60B"
                  >{{data.status_desc}}</div>
                  <div
                    class="order_money already"
                    v-else-if="data.status_desc=='已失效'"
                    style="color:#999999"
                  >{{data.status_desc}}</div>
                  <div
                    class="order_money already"
                    v-else-if="data.status_desc=='已到账'"
                    style="color:#444444"
                  >已入账</div>
                </div>

                <div class="order_content">
                  <img :src="data.goods_image" class="order_left" alt />
                  <div class="order_right">
                    <div class="order-title">
                      <span :selectable="true" class="title-text">{{data.goods_name}}</span>
                    </div>
                    <div class="order-number">
                      <span class="number fs_30">订单号:{{data.order_id}}</span>
                      <span class="zk-ctr" v-clipboard:copy="data.order_id" @click.stop="_copy">复制</span>
                    </div>
                    <div class="order-creat">
                      <span class="creat fs_30">
                        订单金额:
                        <span>{{data.order_amount}}</span>
                      </span>
                    </div>
                    <div class="order-cese">
                      <span class="order-pay">
                        预估收益 ¥
                        <span>{{data.user_amount}}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </van-cell-group>
            </van-list>
          </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ls from "@/libs/localstore";
import { List, CellGroup, Cell, Tab, Tabs, PullRefresh } from "vant";
import { withDrawCash, getUnionBill, getWithdrawTimes } from "@/api/wallet";
import VueClipboards from "vue-clipboards";

Vue.use(List)
  .use(CellGroup)
  .use(Cell)
  .use(Tabs)
  .use(Tab)
  .use(List)
  .use(PullRefresh)
  .use(VueClipboards);

export default {
  data() {
    return {
      img: {},
      tab: [
        { title: "待结算", type: "1", page: 1, data: [] },
        { title: "已结算", type: "5", page: 1, data: [] },
        { title: "已入账", type: "6", page: 1, data: [] },
        { title: "已失效", type: "-1", page: 1, data: [] },
      ],
      tabIndex: 0,
      loading: false,
      finished: false,
      refreshing: false,
      amount: {},
    };
  },
  mounted() {
    this.$toast.loading({
      message: "加载中...",
      duration: 0,
      loadingType: "spinner",
      forbidClick: true,
    });
    this._getWithdrawTimes();
  },
  methods: {
    //粘贴
    _copy() {
      this.$toast("复制成功");
    },
    //获取可提现金额
    _getWithdrawTimes() {
      let params = {
         
      };
      getWithdrawTimes(params)
        .then((res) => {
          if (res.errCode == 0) {
            this.amount = res.data;
          }
        })
        .catch((err) => {});
    },
    //刷新
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.tab[this.tabIndex].page = 1;
      this.tab[this.tabIndex].data = [];
      this._getData();
    },
    //tab栏切换
    change_tab(index) {
      this.tabIndex = index;
      if (this.tab[this.tabIndex].data.length <= 0) {
        this.onRefresh();
      }
    },
    //获取数据
    _getData() {
      if (this.refreshing) {
        this.tab[this.tabIndex].data = [];
        this.refreshing = false;
      }
      let params = {
         
        type: this.tab[this.tabIndex].type,
        page: this.tab[this.tabIndex].page,
        rows: 10,
      };
      getUnionBill(params)
        .then((res) => {
          if (res.errCode == 0 && res.data.length > 0) {
            this.tab[this.tabIndex].data = this.tab[this.tabIndex].data.concat(
              res.data
            );
            this.tab[this.tabIndex].page++;
            this.loading = false;
          } else {
            this.finished = true;
          }
          this.$toast.clear();
          this.loading = false;
        })
        .catch((err) => {
          this.$toast.clear();
          this.loading = false;
          this.finished = true;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #f7f7f7;
}
.account {
  width: 100%;
  height: 100px;
  background: linear-gradient(
    270deg,
    rgba(255, 108, 115, 1),
    rgba(255, 85, 149, 1)
  );
  color: rgba(255, 255, 255, 1);
  font-family: PingFang SC;
  padding: 25px 0 25px 25px;
  box-sizing: border-box;
  p {
    line-height: 19px;
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 14px;
  }
  span {
    font-size: 15px;
    line-height: 15px;
    font-weight: 400;
  }
}
.order-item {
  padding: 15px 19px;
  background: #fff;
  margin: 8px 0;
  box-shadow: 0 2px 10px 0 rgba(201, 201, 201, 0.2);
}

.orderLeft {
  flex: none;
  width: 100px;
  max-height: 100px;
  border-radius: 5px;
  padding-right: 10px;
  font-size: 0;
  overflow: hidden;
}

.orderRight {
  flex: 1;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.order-title {
  width: 235px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #333333;
  line-height: 15px;
}

.order-creat {
  overflow: hidden;
  font-size: 12px;
  color: #7e7e7e;
  width: 100%;
  margin-top: 10px;
  line-height: 12px;
  font-family: PingFang SC;
  font-weight: 400;

  .creat {
    font-size: 12px;
    color: #9b9b9b;
    span {
      color: #444444;
    }
  }
}

.zk-price {
  padding: 3px;
}

.order-number {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  font-size: 12px;
  line-height: 12px;
  color: #7e7e7e;
}

.number {
  color: #9b9b9b;
  font-size: 12px;
}

.zk-ctr {
  width: 32px;
  line-height: 16px;
  text-align: center;
  font-size: 11px;
  color: #999999;
  font-weight: 400;
  height: 16px;
  border: 1px solid rgba(176, 176, 176, 1);
  border-radius: 8px;
  margin-left: 10px;
}

.order-cese {
  position: absolute;
  font-size: 12px;
  right: 0;
  bottom: 0;
  text-align: right;

  .order-pay {
    color: #ff5050;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 12px;
    span {
      font-size: 17px;
    }
  }
}

.order-ext {
  color: #666666;
}

.loading {
  line-height: 1em;
  text-align: center;
  color: #888;
  margin-top: 10px;
}

.imageCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imageBig {
  width: 376px;
  height: 377px;
}

.order_body {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.order_money {
  flex: 1;
  font-size: 13px;
  color: #6d6d6d;
  line-height: 13px;
}

.already {
  font-size: 14px;
  color: #ff8a80;
  text-align: right;
}

.order_content {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.order_left {
  flex: none;
  width: 85px;
  max-height: 85px;
  border-radius: 10px;
  margin-right: 10px;
  overflow: hidden;
}

.order_right {
  position: relative;
  flex: 1;
}
.van-list__finished-text {
  background-color: transparent;
}
</style>
